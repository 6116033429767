<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'Resources',
  data: () => ({

  })
}
</script>